import { getLocalStorage } from '@shein/common-function'
import { CollectData } from './CollectData'
import { RouterAction } from './RouterAction.js'
import { TrackingController } from './TrackingController'
import configs from '../config/index'
import RouterControl from '../routerControl/index.js'

function registerUserActionTracker(configs = [], { debug = false }) {
  const trackingController = new TrackingController({ debug })

  if (window.analysisBeforeInterceptor) {
    // 埋点拦截器
    window.analysisBeforeInterceptor.use(async result => {
      trackingController.triggerCollectDataHandlers(result.data)
      if (result.data?.activity_param?._actionId) {
        delete result.data.activity_param._actionId
      }
      return result
    })
  }

  function initUserActionTracker(configs = []) {
    function useUserAcitonTracker(config) {
      const collectDataInstance = new CollectData(config)
      const routerActionInstance = new RouterAction(config)
      trackingController.registerHandler({
        key: config.featureKey,
        collectDataInstance,
        routerActionInstance
      })
      return { collectDataInstance, routerActionInstance }
    }

    const instances = {}
    configs.forEach(config => {
      const { collectDataInstance, routerActionInstance } =
        useUserAcitonTracker(config)
      instances[config.featureKey] = {
        get() {
          return config.onTrigger({
            collectData: collectDataInstance.getData(),
            routerAction: routerActionInstance.getData()
          })
        },
        set(type, value) {
          if (type === 'collectData') {
            collectDataInstance.trigger(value)
          } else if (type === 'routerAction') {
            routerActionInstance.trigger(value)
          }
        },
        onceSet(value, { to, from }) {
          collectDataInstance.onceSet(value, { to, from })
        },
        startCollect(data) { // 立即开始收集
          routerActionInstance.onStart(data.to, data.from)
        }
      }
    })
    return instances
  }

  const instances = initUserActionTracker(configs)
  return {
    trackingController,
    instances
  }
}

let instances = null
function createAppUserActionTracker() {
  if (typeof window === 'undefined' || gbCommonInfo?.USER_ACTION_CLOSE == '1')  return 
  const isDebug = getLocalStorage('user_action_log') == '1' || window.location.href.includes('local-shein.clbug.com')
  const { instances: currentInstances, trackingController } = registerUserActionTracker(configs, { debug: isDebug })
  instances = currentInstances
  window._userActionTracker = instances

  const routerControl = new RouterControl()
  routerControl.registerHandler((to, from) => {
    try {
      trackingController.triggerRouterActionHandlers(
        {
          path: to.path,
          query: to.query,
        },
        {
          path: from.path,
          query: from.query,
        }
      )
    } catch(e) {
      console.error(e)
    }
  })
  routerControl.init()
}

/**
 * @description: 获取用户行为数据
 * @return {Object | null} { value, collectData, routerAction } | null
 * */
function getUserActionTrackerData(featureKey) {
  if (!instances) return null
  const instance = instances[featureKey]
  if (instance) {
    return instance.get()
  }
}

/**
 * @description: 设置用户行为数据
 * @param {String} featureKey
 */
function setUserActionTrackerData(featureKey, data) {
  if (!instances) return
  const instance = instances[featureKey]
  if (instance && data) {
    instance.set('collectData', data)
  }
}

/**
 * @description: 设置用户行为数据只使用一次, 之后数据会被清空
 * @param {String} featureKey
 * @param {Object} data 数据 
 * @param {Object} to 跳转目标 需要伪造路由
 * @param {Object} from 跳转来源 使用当前页面的路由信息即可
 * @return {string} 生成的id，用于混入数据重复时的去重
 */
function setUserActionTrackerDataOnce(featureKey, data = {}, { to, from } = {}) {
  if (!instances) {
    throw new Error('instances is not ready')
  }
  const instance = instances[featureKey]
  if (instance && data) {
    // to: { path, query, name, params } path和name必须
    return instance.onceSet(data, { to, from }) // 传递to，from时立即为开始记录，防止数据丢失
  }
}

/**
 * @description: 立即开始收集函数
 * @param {String} featureKey
 * @param {Object} to 跳转目标 需要伪造路由, { path, name }
 * @param {Object} from 跳转来源 使用当前页面的路由信息即可
 */
function startCollectData(featureKey, { to, from }) {
  if (!instances) {
    throw new Error('instances is not ready')
  }
  const instance = instances[featureKey]
  if (instance) {
    instance.startCollect({ to, from })
  }
}

export {
  createAppUserActionTracker,
  getUserActionTrackerData,
  setUserActionTrackerData,
  setUserActionTrackerDataOnce,
  startCollectData
}
